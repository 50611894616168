import { Button, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm } from "react-hook-form";

import { Input } from "../../../components/form/input";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { api } from "../../../service/api";
import { IMAGE_LOGIN, LOGO, LOGO_BRANCA } from "../../../assets";

type authFormData = {
  email: string;
};

const authFormDataSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
});

export function RecoverPassword() {
  const navigate = useNavigate();

  const { isAuthenticated, isAtuhLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(authFormDataSchema) });

  if (isAtuhLoading) return;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  const handleSend: SubmitHandler<authFormData> = async (value) => {
    try {
      await api.post("/recoverpassword", {
        email: value.email,
      });

      navigate(`/atualizar-senha/${value.email}`);
    } catch (error) {
      navigate(`/atualizar-senha/${value.email}`);
    }
  };

  return (
    <Flex w="100%" h="100vh" alignItems="center" justifyContent="center">
      <Flex
        style={{ height: "100vh" }}
        flexDirection="column"
        bg="white"
        w={["100%", "100%", "55%"]}
        paddingLeft={["20px", "20px", "0px"]}
        paddingRight={["20px", "20px", "0px"]}
      >
        <Flex
          style={{ marginTop: "64px" }}
          margin={["auto", "auto", "auto"]}
          marginLeft={["auto", "0px", "70px"]}
        >
          <Image
            src={LOGO}
            style={{ width: "280px", height: "40px" }}
            alt="logo"
          />
        </Flex>

        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          as="form"
          onSubmit={handleSubmit(handleSend)}
        >
          <Flex
            flexDirection="column"
            style={{ width: "450px", height: "400px" }}
          >
            <Text style={{ fontWeight: 600, fontSize: 24 }} color="gray.200">
              Recuperação de senha
            </Text>
            <Flex flexDirection="column" style={{ marginTop: 26 }}>
              <Input
                label="Endereço de email"
                placeholder="seu@email.com"
                type="email"
                error={errors.email}
                {...register("email")}
              />

              <Button
                isLoading={isSubmitting}
                type="submit"
                bg="blue.400"
                color="white"
                style={{
                  borderRadius: 10,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "blue.400",
                }}
                mt="6"
                size="lg"
              >
                Recuperar senha
              </Button>

              <Stack
                align="center"
                justifyContent="center"
                direction="row"
                style={{ marginTop: 21 }}
              >
                <Link
                  href="/Login"
                  color="gray.200"
                  style={{ fontWeight: 600, fontSize: 16 }}
                >
                  Voltar
                </Link>
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{ height: "100vh", width: "45%" }}
        display={["none", "none", "flex"]}
        bg="white"
        position="relative"
      >
        <Image
          src={IMAGE_LOGIN}
          style={{ width: "100%", objectFit: "cover" }}
          alt="imagem robo"
        />
        <Flex
          position="absolute"
          top="38%"
          left="30%"
          width="396px"
          height="263px"
        >
          <Image
            src={LOGO_BRANCA}
            style={{ width: "396px", height: "263px" }}
            alt="logo"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
