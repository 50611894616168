import { Button, Flex, Text } from "@chakra-ui/react";
import { ChatbotMessageDTO } from "../../../../../dtos/ChatbotMessageDTO";
import { useEffect, useState } from "react";

interface typeValues {
  type: string;
  text: string;
}

type Props = {
  data: ChatbotMessageDTO;
  type: string;
  onClick: (type: typeValues) => void;
  upScrollChatValue: (value: string) => void;
  textSeleted?: string[];
  isLoading?: boolean;
  onSend?: () => void;
};
export function BoxMessageBot({
  data,
  onClick,
  type,
  upScrollChatValue,
  textSeleted,
  isLoading = false,
  onSend,
}: Props) {
  const [text, setText] = useState("");

  const typeWriter = (value: string, i = 0) => {
    if (i < value.length) {
      setText(value.slice(0, i + 1));
      setTimeout(() => {
        typeWriter(value, i + 1);
      }, 10);
    }
  };

  useEffect(() => {
    typeWriter(data.message_bot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.message_bot]);

  useEffect(() => {
    if (text) {
      // upScrollChatValue(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <Flex flexDirection="column" flexWrap="wrap">
      {/* <Text fontSize="12px" color="#1E1E1E" fontWeight="bold">
        {title}
      </Text> */}
      {data.type_read ? (
        <Text
          fontSize={["14px", "14px", "16px"]}
          fontWeight={400}
          color="#1E1E1E"
        >
          {data.message_bot}
        </Text>
      ) : (
        <Text
          fontSize={["14px", "14px", "16px"]}
          fontWeight={400}
          color="#1E1E1E"
        >
          {text}
        </Text>
      )}
      {data.name === "question_me_question" && (
        <Flex
          mt="10px"
          gap="10px"
          maxW={["200px", "200px", "600px"]}
          flexWrap="wrap"
        >
          {data.name === "question_me_question" &&
            getText(data.question_text, data.question_result)?.map(
              (item, _) => (
                <Flex mt="1px" gap="5px" key={item.type}>
                  <Button
                    backgroundColor="blue.400"
                    colorScheme="teal"
                    size="xs"
                    onClick={() => {
                      onClick(item);
                    }}
                    isDisabled={type !== "question_me_question" || isLoading}
                  >
                    {item.text}
                  </Button>
                </Flex>
              )
            )}
        </Flex>
      )}

      {data.name === "question_biography" && (
        <Flex
          mt="10px"
          gap="10px"
          maxW={["200px", "200px", "600px"]}
          flexWrap="wrap"
        >
          {data.name === "question_biography" &&
            getText(data.question_text, data.question_result)?.map(
              (item, _) => (
                <Flex mt="1px" gap="5px" key={item.type}>
                  <Button
                    backgroundColor="blue.400"
                    colorScheme="teal"
                    size="xs"
                    onClick={() => {
                      onClick(item);
                    }}
                    isDisabled={type !== "question_biography" || isLoading}
                  >
                    {item.text}
                  </Button>
                </Flex>
              )
            )}
        </Flex>
      )}
      {data.name === "question_referring_write" && (
        <Flex
          mt="10px"
          gap="10px"
          maxW={["200px", "200px", "600px"]}
          flexWrap="wrap"
        >
          {data.name === "question_referring_write" &&
            getText(data.question_text, data.question_result)?.map(
              (item, _) => (
                <Flex mt="1px" gap="5px" key={item.type}>
                  <Button
                    backgroundColor="blue.400"
                    colorScheme="teal"
                    size="xs"
                    onClick={() => {
                      onClick(item);
                    }}
                    isDisabled={
                      type !== "question_referring_write" || isLoading
                    }
                  >
                    {item.text}
                  </Button>
                </Flex>
              )
            )}
        </Flex>
      )}
      {data.name === "question_type_write" && (
        <>
          <Flex
            mt="10px"
            gap="10px"
            maxW={["200px", "200px", "600px"]}
            flexWrap="wrap"
          >
            {data.name === "question_type_write" &&
              getText(data.question_text, data.question_result)?.map(
                (item, _) => (
                  <Flex mt="1px" gap="5px" key={item.type}>
                    <Button
                      backgroundColor={
                        textSeleted?.find((textSec) => textSec === item.text)
                          ? "blue.400"
                          : "white"
                      }
                      borderWidth="1px"
                      borderColor="blue.400"
                      colorScheme="teal"
                      color={
                        textSeleted?.find((textSec) => textSec === item.text)
                          ? "white"
                          : "black"
                      }
                      size="xs"
                      onClick={() => {
                        onClick(item);
                      }}
                      isDisabled={type !== "question_type_write" || isLoading}
                    >
                      {item.text}
                    </Button>
                  </Flex>
                )
              )}
          </Flex>
          {textSeleted && textSeleted.length > 0 && (
            <Flex
              mt="20px"
              borderTop="1px"
              paddingTop="10px"
              borderTopColor="#ccc"
              maxW="600px"
              width="100%"
            >
              <Button
                backgroundColor="blue.400"
                width="200px"
                colorScheme="teal"
                size="xs"
                onClick={() => {
                  if (onSend) {
                    onSend();
                  }
                }}
                isDisabled={type !== "question_type_write" || isLoading}
              >
                {textSeleted.length <= 0
                  ? `Enviar opção selecionada`
                  : `Enviar opções selecionadas`}
              </Button>
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
}

function getText(text: string | null, text2: string | null) {
  if (!text || !text2) return;

  var arrayText = text.split("|");
  var arrayText2 = text2.split("|");

  var values = [];

  for (let index = 0; index < arrayText.length; index++) {
    const element = arrayText[index];
    values.push({
      text: element,
      type: arrayText2[index],
    });
  }
  return values;
}
