import dayjs from "dayjs";

export const URL_WEB = "";

export function splitFullname(fullname: string) {
  const arrayName = fullname.split(" ");

  const firstName = arrayName[0];
  if (arrayName.length > 1) {
    const lastName = arrayName[arrayName.length - 1];
    return `${firstName} ${lastName}`;
  }

  return `${firstName}`;
}

export function formatedExtenseDate() {
  try {
    const date = new Date();
    var options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return date.toLocaleDateString("pt-BR", options);
  } catch (err) {
    return "";
  }
}

export function formatDecimal(value: number) {
  try {
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } catch (error) {
    return value;
  }
}

export function formatCurrency(price: number) {
  try {
    let newPrice = Math.round(price * 100);

    let value = String(newPrice);
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    if (price <= 0.99) return `0,${value}`;
    return value;
  } catch (error) {
    return price;
  }
}

export function clearPrice(price: string) {
  return price
    .replace("R$", "")
    .replace(" ", "")
    .replace(".", "")
    .replace(",", ".");
}

export function typeUpdatePrice(value: string) {
  return value === "accrual" ? "Acréscimo" : "Redução";
}

export function statusUpdatePrice(value: string) {
  switch (value) {
    case "progress":
      return "Andamento";
    case "finished":
    case "approved":
      return "Finalizada";
    case "canceled":
    case "repproved":
      return "Cancelada";
    default:
      return "";
  }
}

export function clientTypePayment(value: string) {
  switch (value) {
    case "advance_credit":
      return "Crédito com pagamento antecipado";
    case "weekly_payment":
      return "Pagamento postecipado semanal";
    case "biweekly_payment":
      return "Pagamento postecipado quinzenal";
    case "monthly_payment":
      return "Pagamento postecipado mensal";
    default:
      return "";
  }
}

export function creditTypePayment(value: string) {
  switch (value) {
    case "money":
      return "Dinheiro";
    case "deposit":
      return "Depósito";
    case "transfer":
      return "Transferência";
    default:
      return "";
  }
}
export function typeQuestion(value: string) {
  switch (value) {
    case "Dissertativa Espaço em branco":
    case "Espaço em branco":
      return "blank_space";
    case "Múltipla escolha":
      return "multiple_choice";
    case "Verdadeiro ou Falso":
      return "true_or_false";
    default:
      return "";
  }
}

export function typeQuestionBr(value: string, filter?: boolean) {
  if (filter && value === "blank_space") {
    return "Dissertativa Espaço em branco";
  }

  switch (value) {
    case "blank_space":
      return "Espaço em branco";
    case "multiple_choice":
      return "Múltipla escolha";
    case "true_or_false":
      return "Verdadeiro ou Falso";
    default:
      return "";
  }
}

export function statusContract(value: string) {
  switch (value) {
    case "registered":
      return "Andamento";
    case "confirmed":
      return "Aprovado";
    case "canceled":
      return "Cancelado";
    default:
      return "";
  }
}

export function typeOperagions(value: string) {
  switch (value) {
    case "fleet_vehicle":
      return "Veículo da frota";
    case "not_registered":
      return "Não cadastrado";
    case "tank_convoy":
      return "Tanque comboio";
    case "travel_fleet":
      return "Frota em viagem";
    default:
      return "";
  }
}

export function typeCaregoryQuestion(value: string) {
  switch (value) {
    case "subject":
      return "Assunto";
    case "discipline":
      return "Disciplina";
    case "jury":
      return "Banca";
    default:
      return "";
  }
}

export function statusSimulator(value: string) {
  switch (value) {
    case "not_started":
      return "Não iniciado";
    case "started":
      return "Iniciado";
    case "finished":
      return "Finalizado";
    default:
      return "";
  }
}

export function calcPriceProductContract(price: number, discount: number) {
  if (discount === 0) return 0;

  let calcDiscount = (discount / 100) * -1;
  let newPrice = price * (1 + calcDiscount);
  return newPrice;
}

export function formatedMonthDay(date: string) {
  try {
    if (!date) return "";

    let newDate = date.split("-");

    let formatedDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;

    return String(formatedDate);
  } catch (error) {
    return "";
  }
}

export function formatedDateTimer(date: string, isNotTimer?: boolean) {
  try {
    if (!date) return "";
    let infoDate = date.split(" ");
    let newDate = infoDate[0].split("-");
    let timer = infoDate[1];

    let formatedDate = "";
    if (isNotTimer) {
      formatedDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
    } else {
      formatedDate = `${newDate[2]}/${newDate[1]}/${newDate[0]} ${timer}`;
    }

    return String(formatedDate);
  } catch (error) {
    return "";
  }
}

export function formatedDateTimerUs(date: string) {
  try {
    if (!date) return "";
    let infoDate = date.split("T");
    let newDate = infoDate[0].split("-");
    let timer = infoDate[1].split(":");

    let formatedDate = `${newDate[2]}/${newDate[1]}/${newDate[0]} ${timer[0]}:${timer[1]}`;

    return String(formatedDate);
  } catch (error) {
    return "";
  }
}

export function lmWord(text: string, limit: number) {
  return text.substring(0, limit) + (text.length > limit ? "..." : "");
}

export function differenceBetweenHours(
  startDate: string | null,
  endDate: string | null
) {
  if (!startDate || !endDate) return "00:00";

  let newStartDate = new Date(startDate);
  let newEndDate = new Date(endDate);
  let timer = (newEndDate.getTime() - newStartDate.getTime()) / 1000;

  let minutes = parseInt((timer / 60).toString(), 10);
  let seconds = parseInt((timer % 60).toString(), 10);
  let newMinutes = minutes < 10 ? "0" + minutes : minutes;
  let newSeconds = seconds < 10 ? "0" + seconds : seconds;
  return newMinutes + ":" + newSeconds;
}

export function getActualDateTimer() {
  var now = dayjs();
  return now.format();
}
