import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useGoogleLogin } from "@react-oauth/google";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { Input } from "../../../components/form/input";
import { InputPassword } from "../../../components/form/inputPassword";

import { api } from "../../../service/api";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { IMAGE_LOGIN, LOGO, LOGO_BRANCA } from "../../../assets";
import { useGetUserInfoGoogle } from "../../../service/getUserInfoGoogle";
import { RiGoogleFill } from "react-icons/ri";
import { useState } from "react";

type createFormData = {
  id_google?: string;
  first_name: string;
  surname: string;
  email: string;
  password: string;
  password_confirm: string;
};

const createFormDataSchema = yup.object().shape({
  id_google: yup.string().optional(),
  first_name: yup.string().required("Campo obrigatório."),
  surname: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .required("Ccampo obrigatório.")
    .email("Email não é valido"),
  password: yup
    .string()
    .required("Campo obrigatório.")
    .min(6, "A senha deve ter pelo menos 6 dígitos"),
  password_confirm: yup
    .string()
    .required("Campo obrigatório.")
    .oneOf([yup.ref("password")], "Confirme a senha"),
});

export function RegisterAccount() {
  const navigate = useNavigate();
  const toast = useToast();

  const { isAuthenticated, isAtuhLoading, signIn } = useAuth();

  const { getUserInfoGoogle } = useGetUserInfoGoogle();
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  const createAccountGoogle = useGoogleLogin({
    flow: "implicit",
    onSuccess: async (tokenResponse) => {
      setIsLoadingUser(true);
      const response = await getUserInfoGoogle(tokenResponse.access_token);
      await sendCreateAccount({
        id_google: response.id,
        email: response.email,
        first_name: response.given_name,
        surname: response.family_name,
        password: response.id,
        password_confirm: response.id,
      });
    },
  });

  if (isAtuhLoading) return <Box />;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    await sendCreateAccount(value);
  };

  const sendCreateAccount = async (value: createFormData) => {
    try {
      await api.post("account/create", value);

      await signIn({
        email: value.email,
        password: value.password,
        isCreateToken: true,
      });
      navigate("/home");

      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Conta cadastrada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível criar conta.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoadingUser(false);
    }
  };

  return (
    <Flex w="100%" h="100vh" alignItems="center" justifyContent="center">
      <Flex
        style={{ height: "100vh" }}
        flexDirection="column"
        bg="white"
        w={["100%", "100%", "55%"]}
        paddingLeft={["20px", "20px", "0px"]}
        paddingRight={["20px", "20px", "0px"]}
      >
        <Flex
          style={{ marginTop: "64px" }}
          margin={["auto", "auto", "auto"]}
          marginLeft={["auto", "0px", "70px"]}
        >
          <Image
            src={LOGO}
            style={{ width: "280px", height: "40px" }}
            alt="logo"
          />
        </Flex>

        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          as="form"
          onSubmit={handleSubmit(handleSend)}
        >
          <Flex flexDirection="column" style={{ width: "450px" }}>
            <Text style={{ fontWeight: 500, fontSize: 24 }} color="gray.200">
              Crie sua conta
            </Text>
            <Flex flexDirection="column" style={{ marginTop: 26 }}>
              <Input
                label="Nome"
                placeholder="Seu nome"
                error={errors.first_name}
                {...register("first_name")}
              />
              <Box style={{ marginTop: 10 }}>
                <Input
                  label="Sobrenome"
                  placeholder="Seu último nome"
                  error={errors.surname}
                  {...register("surname")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <Input
                  label="Endereço de email"
                  placeholder="seu@email.com"
                  type="email"
                  error={errors.email}
                  {...register("email")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputPassword
                  label="Senha"
                  type="password"
                  style={{ fontSize: 14 }}
                  placeholder="Sua senha"
                  {...register("password")}
                  error={errors.password}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputPassword
                  label="Confirmar senha"
                  type="password"
                  style={{ fontSize: 14 }}
                  placeholder="Confirme sua senha"
                  {...register("password_confirm")}
                  error={errors.password_confirm}
                />
              </Box>

              <Button
                isLoading={isSubmitting || isLoadingUser}
                type="submit"
                bg="blue.400"
                color="white"
                style={{
                  borderRadius: 10,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "blue.400",
                }}
                mt="6"
                size="lg"
              >
                Criar minha conta
              </Button>

              <Text margin="auto" mt="6">
                OU
              </Text>

              <Button
                isLoading={isSubmitting || isLoadingUser}
                type="button"
                bg="white"
                borderColor="blue.400"
                borderWidth="1px"
                color="black"
                style={{
                  borderRadius: 10,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "#f6f6f6",
                }}
                mt="6"
                size="lg"
                onClick={() => {
                  createAccountGoogle();
                }}
                leftIcon={<Icon as={RiGoogleFill} color="black" />}
              >
                Criar minha conta com Google
              </Button>

              <Stack
                align="center"
                justifyContent="center"
                direction="row"
                style={{ marginTop: 21 }}
              >
                <Text style={{ fontWeight: 400, fontSize: 16 }} color="#1E1E1E">
                  Já tem conta
                </Text>

                <Link
                  href="/Login"
                  color="blue.400"
                  style={{ fontWeight: 400, fontSize: 16 }}
                >
                  Faça Login
                </Link>
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{ height: "100vh", width: "45%" }}
        display={["none", "none", "flex"]}
        bg="white"
        position="relative"
      >
        <Image
          src={IMAGE_LOGIN}
          style={{ width: "100%", objectFit: "cover" }}
          alt="imagem robo"
        />
        <Flex
          position="absolute"
          top="38%"
          left="30%"
          width="396px"
          height="263px"
        >
          <Image
            src={LOGO_BRANCA}
            style={{ width: "396px", height: "263px" }}
            alt="logo"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
